import { Logger } from "./services/logger.interface";

export const getStack = (log: Logger) => {
    return log.stack ? log.stack : '\r\n';

};

export const formatDate = (date: string): string =>{
    const date2 = new Date(date);
    const pad = (num: number): string => (num < 10 ? '0' + num : num.toString());

    const day = pad(date2.getDate());
    const month = pad(date2.getMonth() + 1); // Los meses en JavaScript van de 0 a 11
    const year = date2.getFullYear();
    const hours = pad(date2.getHours());
    const minutes = pad(date2.getMinutes());
    const seconds = pad(date2.getSeconds());

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }