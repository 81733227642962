import { Component, OnInit } from '@angular/core';
import { LoginService, User } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'log-schedule-meds';
  user!: User | null;
  constructor(private loginService: LoginService){}

  ngOnInit(): void {
    this.loginService.currentUser.subscribe(user => {
      this.user = user;
    });
  }
  
}
