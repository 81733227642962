import { Component, OnInit } from '@angular/core';
import { LoginService, User } from 'src/app/services/login.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit{
  user!: User | null;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  async logout() {
    await this.loginService.logout();
    window.location.reload();
  }



}
