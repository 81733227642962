import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  form!: FormGroup;

  constructor(private formBuilder: FormBuilder, private readonly loginService: LoginService, private router: Router) { 
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  
  onSubmit(){
    if(this.form.valid){

      this.loginService.login(this.form.value.username, this.form.value.password).then((response) => {
        
        if(response.token){
          this.router.navigate(["/private/logs"], { queryParams: { type: 'SAP' } });
        }
        else{
          Swal.fire({
            icon: 'error',
            title: response.message,
            confirmButtonColor: '#0d6efd'
          });
        }
      });
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Formulario inválido',
        confirmButtonColor: '#0d6efd'
      });
    }
  }
 
}
