import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from 'src/app/services/log.service';
import { Logger } from 'src/app/services/logger.interface';
import { getStack, formatDate } from 'src/app/util';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-log-detail',
  templateUrl: './page-log-detail.component.html',
  styleUrls: ['./page-log-detail.component.css']
})
export class PageLogDetailComponent implements OnInit {

  details!: Array<Logger>;
  system!: string;
  message!: string;
  error!: string;
  loading: boolean = false;

  constructor(
    private readonly logService: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {

      this.system = params['type'];
      this.message = params['msg'];
      this.error = params['error'];
      //this.details = await this.logService.getLogsByMessage(this.message, this.error);
      this.loadData();
    });

  }

  async loadData(){
    this.loading = true;
    this.details = await this.logService.getLogsByMessage(this.system, this.message, this.error);
    this.loading = false;
  }


  getStack(log: Logger): string {
    return getStack(log);
  }

  formatDate(date: string): string {
    return formatDate(date);
  }

  parseJson(json: string): any {
    return JSON.parse(json);
  }

  async manageLogger(id: number){
    await this.logService.managmentImagesLoggerById(id);
    await this.loadData();
  }

  async deleteLogger(id: number){
    await this.logService.deleteLoggerById(id);
    await this.loadData();
  }

  goBack(){
    this.location.back();
  }


}
