<div class="body">
    <div class="container">
        <div class="row">
            <!-- The image half -->
            <div class="col-md-4 d-none d-md-flex bg-image"></div>


            <!-- The content half -->
            <div class="col-md-8 bg-light">
                <div class="login d-flex align-items-center py-5">

                    <!-- Demo content-->
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <div class="d-flex justify-content-center">
                                    <img src="assets/images/Logo-MEDS-2023.svg" class="img-fluid" alt="Clínica MEDS" style="width: 200px;">
                                </div>
                                <p class="text-muted text-center m-4 display-6">Sistema de logs</p>
                                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                    <div class="form-group mb-3">
                                        <input id="username" formControlName="username" type="text" placeholder="Usuario" required="" autofocus="" class="form-control rounded-pill border-0 shadow-sm px-4">
                                    </div>
                                    <div class="form-group mb-3">
                                        <input id="password" formControlName="password" type="password" placeholder="Contraseña" required="" class="form-control rounded-pill border-0 shadow-sm px-4 text-primary">
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="submit" [disabled]="form.invalid" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">Ingresar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- End -->

                </div>
            </div>
            <!-- End -->

        </div>
    </div>
</div>