import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';
import { PageLoginComponent } from './public/page-login/page-login.component';
import { PageLogsComponent } from './private/page-logs/page-logs.component';
import { AuthorizedGuard } from './guard/authorized.guard';
import { PageLogDetailComponent } from './private/page-log-detail/page-log-detail.component';

const routes: Routes = [
  { path: '', redirectTo: "public/login", pathMatch: "full" },
  { path: "public/login", component: PageLoginComponent },
  {
    path: "private/logs",
    component: PageLogsComponent,
    data: { breadcrumb: 'logs' },
    /*children: [
      {
        path: 'details',
        component: PageLogDetailComponent,
        data: { breadcrumb: 'detail' },
        
      }
    ],
    */
    canActivate: [AuthorizedGuard]
  },
  { path: "private/logs/details", component: PageLogDetailComponent, data: { breadcrumb: 'detail' }, canActivate: [AuthorizedGuard] },
  { path: "**", component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
