import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { PageLoginComponent } from './public/page-login/page-login.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { PageLogsComponent } from './private/page-logs/page-logs.component';
import { NavComponent } from './components/nav/nav.component';
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { PageLogDetailComponent } from './private/page-log-detail/page-log-detail.component';
import { BreadcrumComponent } from './components/breadcrum/breadcrum.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';


@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    LoginComponent,
    PageNotFoundComponent,
    PageLogsComponent,
    NavComponent,
    NewlineToBrPipe,
    PageLogDetailComponent,
    BreadcrumComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
