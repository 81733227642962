import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.css']
})
export class BreadcrumComponent implements OnInit {

  breadcrumbs: Array<{ label: string, url: string }> = [];
  params: Params = {};

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.router.events.pipe(
      filter((event: any) => {
        return event.routerEvent instanceof NavigationEnd;
      }),
      map(() => this.buildBreadcrumb(this.route.root))
    ).subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });

    this.route.queryParams.subscribe((params) => {
      
      this.params = params;
    });

  }

  buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): Array<{ label: string, url: string }> {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      if (label) {
        breadcrumbs.push({ label, url: url });
      }

      return this.buildBreadcrumb(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }


  routerLink(url: string): void {
    this.router.navigate([url], { queryParams: { ...this.params } });
  }
}
