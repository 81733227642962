<!--<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': last }">
            <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
            <span *ngIf="last">{{ breadcrumb.label }}</span>
        </li>
    </ol>
</nav>-->
<div class="container p-2">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': last }">
                <a routerLink="#" (click)="routerLink(breadcrumb.url)">{{ breadcrumb.label }}</a>
            </li>
        </ol>
    </nav>
</div>