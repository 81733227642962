import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr'
})
export class NewlineToBrPipe implements PipeTransform {

  transform(value: string): unknown {
    
    if(!value){
      return value;
    }
    const values = value.split('\\r\\n');
    for(let i = 0; i < values.length; i++){
      values[i] = values[i].trim();
    }
    return values.join('<br/>');
  }

}
