
<!--<breadcrum></breadcrum>-->

<div class="loading" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div class="container-fluid"  *ngIf="!loading">
    <div *ngIf="loggers.length > 0 ; else noLogs">
        <div [class]="'card mt-3 '+log.logger.level" *ngFor="let log of loggers">
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{log.count}}
                <span class="visually-hidden">unread messages</span>
            </span>
            <div class="card-body">
                <h4 class="card-title text-center">{{ log.logger.message }} - {{log.logger.user}}</h4>
                <p class="card-text text-danger mt-3"><strong>Error:</strong> {{ log.logger.error }}</p>
                <p class="card-text"><strong>Stack:</strong></p>
                <div class="json-container">
                    <pre><code [innerHTML]="getStack(log.logger) | newlineToBr"></code></pre>
                </div>
                <p class="card-text"><strong>Metadata:</strong></p>
                <div class="json-container">
                    <pre><code>{{ log.logger.metadata.message | json }}</code></pre>
                </div>
            </div>
            <div class="card-footer text-muted">
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary" (click)="openDetail(log.logger.message, log.logger.error)">Abrir</button>
                </div>
                <p class="text-end"><strong>{{formatDate(log.logger.timestamp )}}</strong></p>
            </div>
        </div>
    </div>
    
</div>

<ng-template #noLogs>
    <div class="alert alert-success mt-3" role="alert">
        <div class="text-center"> No hay logs para mostrar</div>
    </div>
</ng-template>