<app-nav></app-nav>
<div *ngIf="!user; else private_content">
    <router-outlet></router-outlet>
</div>

<ng-template #private_content>
    <div class="container-fluid">
        <div class="row">
            <div class="col-2">
                <app-sidebar></app-sidebar>        
            </div>
            <div class="col-10">
                <router-outlet></router-outlet>
            </div>
        </div>   
    </div>
    
</ng-template>
