
<!--<breadcrum></breadcrum>-->
<div class="container-fluid">
    <button class="btn btn-secondary m-3" (click)="goBack()"><i class="bi bi-arrow-left-circle-fill fs-3"></i> </button>
</div>
<div class="container loading" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div class="container-fluid" *ngIf="!loading">
    <div [class]="'card mt-3 '+log.level" *ngFor="let log of details">
        <div class="card-body">
            <h4 class="card-title text-center">{{ log.message }} - {{log.user}}</h4>
            <p class="card-text text-danger mt-3"><strong>Error:</strong> {{ log.error }}</p>
            <p class="card-text"><strong>Stack:</strong></p>
            <div class="json-container">
                <pre><code [innerHTML]="getStack(log) | newlineToBr"></code></pre>
            </div>
            <p class="card-text"><strong>Metadata:</strong></p>
            <div class="json-container">
                <pre><code>{{ parseJson(log.metadata).message| json }}</code></pre>
            </div>
        </div>
        <div class="card-footer text-muted">
            <div class="d-flex justify-content-center mt-3">
                <button class="btn btn-primary me-3" (click)="manageLogger(log.id)" *ngIf="system==='SAP'">Gestionar</button>
                <button class="btn btn-danger ms-3" (click)="deleteLogger(log.id)">Eliminar</button>
            </div>
            <p class="text-end"><strong>{{formatDate(log.timestamp )}}</strong></p>
        </div>
    </div>
</div>