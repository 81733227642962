<div *ngIf="user">
    <div class="d-flex flex-column p-3 bg-light">
        <span class="fs-4">Sistemas</span>
        <hr>
        <ul class="nav nav-pills flex-column ">
            <li class="nav-item">
                <a routerLink="/private/logs" [queryParams]="{type: 'SAP'}" class="nav-link"  routerLinkActive="active" aria-current="page">
                    <i class="bi bi-prescription me-2" width="16" height="16"></i>
                    Agenda Imágenes
                </a>
            </li>
            <li>
                <a routerLink="/private/logs" [queryParams]="{type: 'pay-appointment'}" class="nav-link"  routerLinkActive="active">
                    <i class="bi bi-coin me-2" width="16" height="16"></i>
                    Pagos citas
                </a>
            </li>
        </ul>
    </div>
</div>
