import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, lastValueFrom, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export interface User{
  username: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private URL = `/api/v1/login`;

  private currentUserSubject: BehaviorSubject<User | null>  = new BehaviorSubject<User | null>(null);
  public readonly currentUser: Observable<User | null> = this.currentUserSubject.asObservable();
  private isAuthenticated: boolean = false;
  
  constructor(private httpClient: HttpClient) { }

  async login(username: string, password: string){

    try{
      const body = {
        username,
        password
      };
  
      const user: User = await lastValueFrom<User>(this.httpClient.post<User>(`${this.URL}/sign-in`, body));
  
      this.isAuthenticated = true;
      this.currentUserSubject.next(user);
  
      return user;
    }
    catch(e: any){
      return {
        ...e.error,
        message: "Usuario o clave incorrecta"
      }
    }
   

  }

  async isLoggedIn(): Promise<boolean>{
    
    await lastValueFrom(this.getProfile());

    return this.isAuthenticated;
  }

  getProfile(){

    return this.httpClient.get<User>(`${this.URL}/profile`).pipe(
      tap(value =>{
        this.isAuthenticated = true;
        this.currentUserSubject.next(value);
      }),
      catchError(error => {
        this.isAuthenticated = false;
        this.currentUserSubject.next(null);
        return of(null);
      })
    );    
  }

  async logout(){
    await lastValueFrom(this.httpClient.post(`${this.URL}/sign-out`, { }));
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
  }
}
