import { Injectable, inject } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Observable, from, lastValueFrom, map, switchMap, take } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard {

  constructor(private readonly loginService: LoginService, private readonly router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const result = from(this.loginService.isLoggedIn()).pipe(
          map(isLogin=>{
            if(isLogin){
              
              return true;
            }
            else{
              this.router.navigateByUrl('public/login');
              return false;
            }
          })
        );
        return result;
  }
  
}
